<template>
  <div class="container-cadastro">
        <!-- -->
      <section class="container-fluid">
        <section class="container" id="topo">
          <div class="row">
            <div class="col-sm-6"><br>
            <h1 style="color:#400041;" class="bold">Cadastre-se</h1>
            </div>
              <div class="col-sm-6"><br>
           <a target="_blank" href="https://organicosinbox.com.br/"><img id=""   src="/img/logo_organicos.png" class="img-fluid d-block mx-auto"  alt="Logo Organicos rodape" /></a>
            </div>
            </div><br>
        </section>

        <div class="row">

          <div class="col-sm-7 fundo-roxo quadro">
           
            
            <div class="espacamento">
              <br><br>
             <p class="text-white">Seja bem-vindo(a) à Tarimba na Cozinha!
<br><br>
Nos juntamos com a Orgânicos In Box para dar a você a gratuidade do acesso permanente à  nossa inovadora plataforma de receitas culinárias.
Aqui você insere os ingredientes que tem à disposição e nós lhe mostramos o que é possível preparar com eles, em receitas descritas em no máximo 6 passos.
A partir de agora você não terá mais “brancos” sobre o que fazer com aquele ingrediente pouco conhecido que veio em sua sacola, ou que você adquiriu por impulso.
Nosso repertório de receitas cresce todo dia, não só com receitas de nossa equipe técnica, mas também  com contribuições dos nossos usuários, portanto, fique também à vontade para mostrar, você também, a sua tarimba na cozinha. 
Ah! Não deixe de ver nossas receitas com reaproveitamento de preparações e receitas com partes consideradas descartáveis dos ingredientes, como talos, sementes, cascas e folhas. Conosco não há desperdício de alimentos.
<br><br>
Para liberarmos seu acesso precisamos apenas que você preencha seus dados de cadastro a seguir.
</p>
              <!-- -->
                <!-- <div class="form-group">
                    <input class="form-control input-cadastro" name="cpf" placeholder="CPF" type="text" v-mask="'###########'" v-model="cpf"/>
                </div> -->

                <div class="form-group">
                    <input class="form-control input-cadastro" name="nome" placeholder="Nome" type="text" v-model="nome"/>
                </div>   

               

              

                 <div class="form-group">
                    <input class="form-control input-cadastro" name="data" placeholder="Data Nascimento" type="text" v-mask="'##/##/####'" v-model="data"/>
                </div>  
                
               

                <!-- <div class="form-group">
                    <select type="text" class="form-control input-cadastro" name="sexo" id="sexo" v-model="genero">
                       <option value="">Gênero</option>
                      <option value="Masculino">Masculino</option>
                      <option value="Feminino">Feminino</option>
                      <option value="Outro">Outro</option>
                    
                    </select>
                </div>  -->

                 <div class="form-group">
                    <input v-on:keyup="buscarCep" class="form-control input-cadastro" name="cep" placeholder="CEP" type="text" v-mask="'#####-###'" v-model="cep"/>
                </div>  

                <div class="form-group d-none">
                    <input class="form-control input-cadastro" name="cidade" placeholder="Cidade" type="text" v-model="cidade"/>
                </div> 

                  <div class="form-group d-none">
                    <select type="text" class="form-control input-cadastro" name="estado" v-model="estado">
                       <option value="">Estado</option>
                       <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                    
                    </select>
                </div>  

<button type="button" class="btn btn-secondary d-block w-100 text-dark" style="background-color:white;font-weight:bold;" @click="abrirModalInfo" >ENTENDA PORQUE LHE PEDIMOS ESSES DADOS</button>
<p class="txt-cadastro">Nós seguimos a Lei de Proteção de Dados Brasileira.  </p>

                <!-- <div class="form-group">
                    <select type="text" class="form-control input-cadastro" name="nivel" id="nivel" v-model="nivel">
                       <option value="">Nível de Pratica</option>
                        <option value="Iniciante">Iniciante</option>
                        <option value="Intermediário">Intermediário</option>
                        <option value="Avançado">Avançado</option>
                        <option value="Expert">Expert</option>
                    
                    </select>
                </div>  -->
<br>
<h5 class="txt-white">Código Promocional</h5>
                <div class="form-group">
                    <input class="form-control input-cadastro" name="promocao" placeholder="Código promocional (Caso possua)" readonly type="text" v-model="promocao"/>
                </div> 

<br>
                <h5 class="txt-white">Sua História</h5>

                  <div class="form-group">
                    <textarea class="form-control input-cadastro" name="historia" maxlength="1000" placeholder="Conte para gente um pouco sobre você e o que espera da Tarimba na Cozinha" type="text" v-model="historia"></textarea>
                </div>   

                <br>

               

              <!-- -->

             
            </div>
          </div><!-- col -->

          <div class="col-sm-5 fundo-roxo2 quadro px-0 ">
             <div class="espacamento">
            <br>
              <h3 class="txt-white bold">Dados de Acesso</h3><br>
               <p class="txt-cadastro">Para ter acesso, por favor, cadastre também os dados abaixo:
</p><br>

               <div class="form-group">
                    <input class="form-control input-cadastro" name="email" placeholder="E-mail" type="email" v-model="email"/>
                </div>  

                <div class="form-group">
                    <input class="form-control input-cadastro" name="emailConfirma" placeholder="Confirmar E-mail" type="email" v-model="emailConfirma"/>
                </div>  
<br>
               

                <div class="form-group">
                    <input class="form-control input-cadastro" name="senha" placeholder="Senha" type="password" v-model="senha"/>
                </div> 

                <div class="form-group">
                    <input class="form-control input-cadastro" name="senhaConfirma" placeholder="Confirmar Senha" type="password" v-model="senhaConfirma"/>
                </div> 

           </div> 


          <div class="fundo-laranja quadro mx-0">
              <div class="espacamento"><br>
                <p class="txt-white bold">Por último, você precisa ler e aceitar nossos TERMOS DE USO e nossa POLÍTICA DE PRIVACIDADE, e depois efetuar o pagamento do seu plano de assinatura desejado.
</p>
                <ul class="txt-white"><br>
                
                     <li><input type="checkbox" name="termos" v-model="termos" />&nbsp;&nbsp;Li e aceito os <span class="bold" ><router-link style="font-size:0.8em !important;color:#FFF;"  to="/termos-de-uso" target="_blank">TERMOS DE USO</router-link></span></li>
                     <li><input type="checkbox" name="politica" v-model="politica" />&nbsp;&nbsp;Li e aceito as <span class="bold"><router-link style="font-size:0.8em !important;color:#FFF;" to="/politica-de-privacidade" target="_blank">POLÍTICAS DE PRIVACIDADE</router-link></span></li>
                     
                </ul>
<br>
    <p class="txt-white bold">Clique em Finalizar o Cadastro abaixo para poder acessar livremente a plataforma.

</p>
<br>
                <button  v-if="showLoading == false" type="button" role="button" @click="validarCampos" class="bt-enviar-cadastro btn-primary bold" style="display:block !important;">FINALIZAR CADASTRO</button>
                  <div v-if="showLoading" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
           
             <br><br><br><br><br><br><br><br><br>
              </div>
          </div>


          </div><!-- col -->

        </div>
      </section>

 <Degustacao />

      <div class="modal" id="modal-validacao" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Ops! Um erro foi encontrado</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{texto}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>


 <div class="modal" id="modal-info" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">O uso dos seus dados </h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Veja abaixo para que utilizamos cada dado que lhe pedimos</p>
        <ul>
          <li>Seu <strong>Nome</strong> será a sua identificação em comentários e  receitas;</li><br>
          
          <li>A <strong>Data de Nascimento, CEP </strong> e sua <strong>breve apresentação</strong> serão usados anonimamente para melhor ajustar e customizar nosso conteúdo do blog e ofertas de nossos parceiros, disponibilizadas nos espaços de anúncios;</li><br>
          <li>Seu <strong>e-mail</strong>, além de ser usado para operacionalizar seu login, é também eventualmente utilizado por nós, para nos comunicarmos com você, em caso de necessidade.</li>
          
        </ul>

        <p>Você pode ler mais sobre como tratamos seus dados em nossa <router-link data-dismiss="modal" to="/politica-de-privacidade">Política de Privacidade</router-link>.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>


      <!-- -->
  </div>
</template>

<style>
.input-cadastro::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFF !important;
  opacity: 1; /* Firefox */
}

.input-cadastro:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #FFF !important;
}

.input-cadastro::-ms-input-placeholder { /* Microsoft Edge */
  color: #FFF !important;
}

.input-cadastro{color: #FFF !important;border-color: #FFF !important;background:none !important;}
.espacamento{padding:0 40px;}
.fundo-laranja{background:#F18800;padding-bottom:20px;}
.fundo-roxo{background:#400041;padding-bottom:20px;}
.fundo-roxo2{background:#764573;padding-bottom:20px;}
.quadro{min-height:300px;}
.txt-white{color:#FFF;}
.txt-cadastro{color:#FFF;font-size:1.15em;}
.bt-enviar-cadastro {
   background-color: #FFF;
    color: #000;
    min-width:100% !important;
    display:block !important;
    padding:10px 0;
    border:none !important;
}
.bt-enviar-cadastro:hover{background-color: #400041;color:#FFF;}
</style>

<script>
import servidor from '@/servidor'
import Degustacao from '@/components/Degustacao'

export default {
  name: 'Cadastro',
  metaInfo: {
    title: 'Cadastro - Tarimba na cozinha',
    meta:[{name: 'description', 
    content: "Tarimba Na Cozinha - cadastro. Olá! Vamos iniciar o seu cadastro. Aqui, pedimos algumas informações básicas para que possamos conhecê-lo(a) e, assim, fazer do Tarimba na Cozinha a melhor solução para seu dia a dia."}]
  },
  components: {Degustacao},
  data(){
    return {
      promocao:'Cliente Orgânicos In Box',
      showLoading:false,
      semResultados:false,
      nome:'',
      data:'',
      cpf:'',
      genero:'',
      cep:'',
      cidade:'',
      estado:'',
      nivel:'',
      historia:'',
      email:'',
      emailConfirma:'',
      senha:'',
      senhaConfirma:'',
      gratuito:null,
      termos:null,
      politica:null,
      texto:''
    }
  },
  methods:{
    abrirModalDegustacao(){
    window.jQuery('#modal-degustacao').modal('show');
   },
    abrirModalInfo(){
    window.jQuery('#modal-info').modal('show');
   },
    buscarCep(){
      if(this.cep.length == 9){
         fetch('https://viacep.com.br/ws/'+this.cep+'/json/')
          .then((response) => response.json())
          .then((data) => {

            if(data.erro != null){
                this.abrirModal("CEP informado é inválido!");
                this.cep = "";
            }else{
               this.cidade = data.localidade;
               this.estado = data.uf;
            }
          });
      }
      
    },
   validarCampos(){
    
     if(this.nome.length < 4){this.abrirModal("Nome deve conter pelo menos 4 caracteres!");return;}
      if(this.data.length > 0 && this.data.length < 10){this.abrirModal("Data de nascimento incorreta!");return;}
      //if(this.genero.length < 1){this.abrirModal("Campo gênero é obrigatório!");return;}
      //if(this.cidade.length < 3){this.abrirModal("Cidade é obrigatória e deve conter pelo menos 3 caracteres!");return;}
      //if(this.estado.length < 1){this.abrirModal("Estado é obrigatório!");return;}
      if(this.cep.length > 0 && this.cep.length < 9){this.abrirModal("Cep deve conter 9 caracteres!");return;}
      //if(this.cpf.length < 11){this.abrirModal("CPF é obrigatório e deve conter 11 caracteres!");return;}
      //if(this.isValidCPF(this.cpf) == false){this.abrirModal("CPF Inválido!");return;}
      //if(this.nivel.length < 1){this.abrirModal("Nível de pratica é obrigatório!");return;}
      if(this.email.length < 5){this.abrirModal("E-mail inválido!");return;}
      if(this.email != this.emailConfirma){this.abrirModal("Confirmação de E-mail não combina!");return;}
      if(this.senha.length < 8){this.abrirModal("Senha deve conter pelo menos 8 caracteres!");return;}
       if(this.senha != this.senhaConfirma){this.abrirModal("Confirmação de Senha não combina!");return;}
      //if(this.gratuito == null){this.abrirModal("Você não confirmou a respeito do (Tempo de gratuidade do sistema e sobre a cobrança da taxa não ser automática).!");return;}
      if(this.termos == null){this.abrirModal("Você não confirmou a respeito dos (Termos de uso).!");return;}
      if(this.politica == null){this.abrirModal("Você não confirmou a respeito da (Política de privacidade).!");return;}
      this.enviar();
   },

   abrirModal(msg){
    this.texto = msg;
    window.jQuery('#modal-validacao').modal('show');
   },
   isValidCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999" 
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++) 
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(9, 10)) ) return false
    soma = 0
    for (var j = 1; j <= 10; j++) 
        soma = soma + parseInt(cpf.substring(j-1, j)) * (12 - j)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(10, 11) ) ) return false
    return true
    },
    enviar(){
      this.showLoading = true;
      let dados = new FormData();
      dados.append('nome',this.nome);
      dados.append('email',this.email);
      dados.append('senha',this.senha);
      dados.append('genero',this.genero);
      dados.append('cep',this.cep);
      dados.append('cpf',this.cpf);
      dados.append('nivel',this.nivel);
      dados.append('data',this.data);
      dados.append('cidade',this.cidade);
      dados.append('estado',this.estado);
      dados.append('historia',this.historia);
      dados.append('promocao',this.promocao);

      fetch(servidor+'verificacao/usuario',{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            console.log(data);

            if(data.erro != null){
                this.abrirModal(data.erro);
            }else{
               console.log('Registrado com sucesso');
               this.$store.commit('logarUsuario',data);
               this.setCookie('usuario',JSON.stringify(this.logado), this.logado.dias);
                this.$store.commit('showAlertaCadastroFinalizado',true);
               this.$router.push('/perfil');
            }
          }).catch(this.showLoading = false);
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  },
  computed:{
    logado(){
      return this.$store.state.usuario;
    }
  },
  
  mounted(){
     
 this.$scrollTo("#topo");
  }
}
</script>